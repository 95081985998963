@use "src/_app/styles/mixins.scss" as *;
.container {
  color: var(--text_1);
  display: flex;
  gap: 16px;

  @include respond(laptopC) {
    flex-direction: column;
  }

  @include respond(laptop) {
    flex-direction: row;
  }

  @media (width <= 940px) {
    flex-direction: column;
  }

  @media (width <= 767px) {
    flex-direction: row;
  }

  @include respond(mobile) {
    flex-direction: column;
  }

  &.adapt {
    @include respond(tabletS) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &.mainPost {
    color: var(--tertiary);
  }

  .info {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap: 6px;

    @include tCaption;
  }

  &.blogPage {
    @include respond(tablet) {
      flex-direction: column;
    }

    @media (width <= 568px) {
      flex-direction: row;
    }
  }
}
