@use "src/_app/styles/mixins.scss" as *;
.plugin {
  width: 100%;
  height: 157px;
  background: var(--bg_22);

  display: grid;
  grid-template-rows: max-content 1fr;
  align-items: flex-end;

  padding: 20px 20px 26px;
  border-radius: 24px;

  @include respond(tablet) {
    background-color: var(--bg_10);
  }

  @include respond(mobileL) {
    padding: 16px;
    height: 136px;
  }

  &:nth-child(2) {
    margin-top: 24px;

    @include respond(laptopC) {
      margin-top: 0;
    }
  }

  &:nth-child(4) {
    @include respond(laptopC) {
      order: 5;
    }
  }

  &:nth-child(5) {
    @include respond(laptopC) {
      order: 6;
    }

    @include respond(tablet) {
      display: none;
    }
  }

  &:nth-child(6) {
    @include respond(laptopC) {
      order: 5;
    }

    @include respond(tablet) {
      display: none;
    }
  }

  &:not(:nth-child(2), :first-child) {
    margin-top: 16px;

    @include respond(laptopC) {
      margin-top: 0;
    }
  }

  &:nth-child(odd):not(:first-child) {
    margin-top: -8px;

    @include respond(laptopC) {
      margin-top: 0;
    }
  }

  &:hover {
    background: var(--bg_21);
    box-shadow: -10px 0 80px rgb(0 0 0 / 10%);

    .footer {
      a {
        background: var(--bg_22);
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    &__text {
      display: flex;
      flex-direction: column;
      row-gap: 2px;

      b {
        margin: 0;
        color: var(--primary);

        @include respond(laptop) {
          font-size: 18px;
        }
      }

      p {
        margin: 0;
        color: var(--text_4);

        @include respond(laptop) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .arrow__wrapper {
      width: 36px;
      height: 36px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bg_21);

      @include respond(tablet) {
        display: none;
      }
    }
  }
}
