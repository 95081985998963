@use "src/_app/styles/mixins.scss" as *;
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: flex-end;
  column-gap: 82px;

  padding: 44px 52px 52px;
  background-color: var(--bg_18);
  border-radius: 24px;

  @include respond(laptopC) {
    grid-template-columns: 1fr;
  }

  @include respond(laptop) {
    padding: 32px;
  }

  @include respond(tablet) {
    border-radius: unset;
    background: var(--bg_20);
    padding: 40px 16px;
  }

  &_reverse {
    column-gap: 85px;

    .text__wrapper {
      grid-column-start: 2;
      grid-row-start: 1;

      @include respond(laptopC) {
        grid-column-start: 1;
      }

      h2 {
        margin: 0;
      }
    }

    @include respond(tablet) {
      background: var(--bg_23);
    }
  }

  .text__wrapper {
    margin-bottom: 32px;

    @include respond(laptopC) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      button {
        height: max-content;
        margin-top: 22px;
      }
    }

    @include respond(tablet) {
      display: block;
      margin-bottom: 32px;
    }

    .text__wrapper_col {
      @include respond(laptopC) {
        display: flex;
        flex-direction: column;
        max-width: 584px;
      }

      @include respond(laptop) {
        max-width: 528px;
      }

      @include respond(tabletL) {
        max-width: 404px;
      }

      @include respond(tablet) {
        max-width: 100%;
      }
    }

    .button {
      @include tTitle_4-medium;
      margin-top: 32px;
      padding: 14px 20px;
      font-family: Golos, sans-serif;
      line-height: 20px;

      @include respond(laptopC) {
        margin-top: 15px;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 48px;

    @include respond(tablet) {
      margin-bottom: 0;
    }
  }

  h2 {
    color: var(--primary);

    @include tTitle_2_main;
    margin: 0 0 16px;

    @include respond(laptopC) {
      font-size: 36px;
    }

    @include respond(tablet) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  p {
    @include tSubtitle;
    color: var(--primary);
    margin: 0;
  }
}

.blocks__wrapper {
  display: grid;
  grid-template-columns: 284px 284px;
  column-gap: 16px;
  justify-self: flex-end;

  @include respond(laptopC) {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    row-gap: 16px;
  }

  @include respond(tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 24px;
  }

  @include respond(mobileL) {
    grid-template-columns: minmax(164px, 300px) minmax(164px, 300px);
    gap: 15px;
    margin-bottom: 24px;
  }
}

.button {
  &_desktop {
    display: block;

    @include respond(tablet) {
      display: none;
    }
  }

  &_tablet {
    display: none;

    @include respond(tablet) {
      display: block;
    }
  }

  @include respond(tablet) {
    max-width: max-content;
    justify-self: center;
  }
}
