@use "src/_app/styles/mixins.scss" as *;
.container {
  width: 100%;
  padding: 48px 0;
  background: var(--secondary);

  @include respond(laptop) {
    padding: 0;
  }

  .wrapper {
    max-width: 896px;
    margin: auto;

    @include respond(laptop) {
      padding: 48px 32px;
    }

    @include respond(tablet) {
      padding: 40px 16px;
    }
  }

  .not_found {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 48px;
    border-radius: 16px;
    background-color: var(--bg_50);
    color: var(--primary);

    @include main_shadow;

    &__title {
      margin: 0;
    }

    &__description {
      word-wrap: break-word;

      p {
        margin: 0;
        display: inline;

        @include respond(tablet) {
          display: block;
          margin-bottom: 16px;
        }
      }
    }

    @include respond(laptop) {
      background-color: unset;
      box-shadow: none;
      padding: 0;
    }
  }
}

.backButton {
  color: var(--primary);
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 32px;

  @include tSubtitle_Semibold;

  @include respond(laptop) {
    margin-bottom: 24px;
  }

  .btn {
    cursor: pointer;
    background-color: var(--bg_catalog_select);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    box-shadow:
      0 0 12px rgb(37 33 41 / 4%),
      0 16px 32px rgb(37 33 41 / 8%);
    border-radius: 50%;
    border: none;

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(-90deg);

      @include rtl {
        transform: rotate(90deg);
      }

      path {
        fill: var(--primary);
      }
    }
  }
}
