@use "src/_app/styles/mixins.scss" as *;
.global {
  border-bottom: 1px solid var(--border_1);
}

.wrapper {
  display: grid;
  row-gap: 16px;

  padding: 24px 0;
  grid-template-areas: 'title buttons';

  max-width: 1280px;
  margin: auto;

  @include respond(tablet) {
    grid-template-areas:
      'buttons '
      'title';
  }

  .showFilterButton {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;
    border: none;

    font-size: 16px;
    font-weight: 500;
    color: var(--primary);
    background: none;
    cursor: pointer;
  }

  .showFilterButton:focus {
    outline: none;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .icon svg {
    transition: transform 0.3s ease-in-out;
  }

  .rotated {
    transform: rotate(180deg);
  }

  .info {
    display: flex;
    align-items: center;

    .info__title {
      margin-right: 40px;

      @include tTitle_2_demibold;

      @include respond(tablet) {
        display: none;
      }
    }

    .info__search_input {
      max-width: 402px; /* WebKit-based browsers will ignore this. */
    }
  }

  .toggle-filter {
    cursor: pointer;
    color: var(--primary);
    transition: color 0.2s ease-in-out;
  }

  .toggle-filter:hover {
    color: var(--primary_dark);
  }
}

.filter__buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
  grid-area: buttons;

  @include respond(tablet) {
    justify-content: space-between;

    button {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ghost__wrapper {
    display: flex;
    align-items: center;
  }
}

.filter {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0 16px;
}
