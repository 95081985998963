@use "src/_app/styles/mixins.scss" as *;
.social__tag {
  width: fit-content;
  padding: 6px 14px;
  border: 1px solid var(--primary);
  color: var(--primary);

  margin-bottom: 8px;
  border-radius: 69px;

  cursor: pointer;

  @include tCaption;
  @include transition;

  &.dark {
    color: var(--primary);
    border: 1px solid white;
    background-color: var(--secondary);
  }

  &:hover {
    background-color: var(--primary);
    color: var(--secondary);
  }
}
