@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  display: flex;
  align-items: center;

  flex-direction: column;
  padding: 232px 0;

  text-align: center;

  @include respond(tablet) {
    padding: 64px 0;
  }
}
