@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  display: flex;
  align-items: center;

  svg {
    color: var(--text_1);
    margin-right: 6px;
  }
}
