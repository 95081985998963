@use "src/_app/styles/mixins.scss" as *;
.property {
  background: var(--bg_22);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 36px 0 24px;

  height: 242px;
  position: relative;
  overflow: hidden;

  @include respond(tablet) {
    padding: 16px;
    height: 212px;
  }

  &:nth-child(2) {
    margin-top: 26px;

    @include respond(laptopC) {
      grid-column: 3/4;
      margin-top: 0;
    }

    @include respond(tablet) {
      grid-column: 2/3;
      grid-row: 1;
    }
  }

  &:nth-child(4) {
    @include respond(laptopC) {
      grid-column: 2/3;
      grid-row: 1;
    }

    @include respond(tablet) {
      grid-column: 2/3;
      grid-row: 2;

      img {
        transform: scale(1.5) translateY(-20px);
      }
    }
  }

  &:not(:nth-child(2), :first-child) {
    margin-top: 16px;

    @include respond(laptopC) {
      margin-top: 0;
    }
  }

  &:nth-child(odd):not(:first-child) {
    margin-top: -8px;

    @include respond(laptopC) {
      margin-top: 0;
    }
  }

  &:nth-child(3) {
    @include respond(laptopC) {
      grid-row-start: 2;
      grid-column: 1 / 4;

      flex-direction: row;
      align-items: center;
      position: relative;

      b {
        max-width: 246px;
      }

      img {
        position: absolute;
        object-fit: scale-down;
        bottom: 0;
        right: 8%;
        left: auto;
        width: unset;
      }

      @include respond(tablet) {
        img {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 0;
          transform: scale(1.3);
        }
      }
    }

    @include respond(tablet) {
      grid-column: 1/2;
      grid-row: 2;
      align-items: flex-start;
    }
  }

  b {
    z-index: 1;
    color: var(--primary);
    height: max-content;
    max-width: 193px;

    @include respond(laptopC) {
      font-size: 18px;
    }

    @include respond(tablet) {
      font-size: 16px;
    }
  }

  img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .icon {
    &_laptop {
      @include respond(tablet) {
        display: none;
      }
    }

    &_tablet {
      display: none;

      @include respond(tablet) {
        display: block;
      }
    }

    &_show {
      display: block;
    }
  }
}
