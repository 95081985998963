@use "src/_app/styles/mixins.scss" as *;
.container {
  width: 100%;
  padding: 0 80px;

  @include respond(laptop) {
    padding: 0 32px;
  }

  @include respond(tabletS) {
    padding: 0 16px;
  }
}

.faq {
  background: var(--secondary);
  color: var(--primary);

  .header_container {
    background: var(--secondary);
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border_3);

    @include respond(laptop) {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    @include respond(tabletS) {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: none;
    }

    .header {
      @include contentWidth;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      @include respond(tablet) {
        flex-direction: column-reverse;
        gap: 24px;
        justify-content: flex-start;
        align-items: baseline;
      }

      @include respond(tabletS) {
        flex-direction: column;
      }

      h1 {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;

        @include respond(tabletS) {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.44px;
        }

        &.sidebar_closed {
          @include respond(tabletS) {
            display: none;
          }
        }
      }

      button.back__button {
        cursor: pointer;
        padding: 6px 12px;
        border: none;
        background: var(--bg_2);
        border-radius: 512px;
        overflow: hidden;
        display: none;

        @include respond(tabletS) {
          &.sidebar_closed {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .info_container {
    padding-top: 56px;
    padding-bottom: 68px;
    position: relative;
    z-index: 1;

    @include respond(laptop) {
      padding-top: 48px;
    }

    @include respond(tabletS) {
      padding-top: 0;
      padding-bottom: 48px;
    }

    .info {
      @include contentWidth;

      display: grid;
      grid-template-columns: 1fr 3fr;
      column-gap: 68px;

      @include respond(laptop) {
        grid-template-columns: 1fr 2fr;
        column-gap: 42px;
      }

      @include respond(tabletS) {
        grid-template-columns: 1fr;
      }

      ul.sidebar {
        min-height: 100vh;
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &.sidebar_closed {
          @include respond(tabletS) {
            display: none;
          }
        }

        li.sidebar_item {
          cursor: pointer;

          .sidebar_item_title {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
              flex-shrink: 0;
            }

            h2.title {
              margin: 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.12px;
            }
          }

          ul.submenu {
            list-style-type: none;
            padding-left: 32px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            li.subItem {
              padding: 8px 16px;
              border-radius: 8px;
              overflow: hidden;

              &.active {
                background: var(--bg_2);
              }

              h3.subTitle {
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.12px;
              }
            }
          }
        }
      }

      .content {
        @include respond(tabletS) {
          display: none;

          &.sidebar_closed {
            display: block;
          }
        }
      }
    }
  }
}

.search_area {
  position: relative;

  @include respond(tablet) {
    width: 100%;
  }
}
