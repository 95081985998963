@use "src/_app/styles/mixins.scss" as *;
.tables_container {
  display: flex;
  flex-flow: row wrap;
  color: white;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 16px;

  .trans_skeleton {
    span {
      padding: 7px 2px;
    }
    margin: 14px;
  }

  @include respond(laptop) {
    flex-direction: column;
    gap: 56px;
    margin-top: 0;
    padding: 80px 32px;
    margin-bottom: 0;
  }

  @include respond(tablet) {
    padding: 40px 16px;
    gap: 40px;
  }

  .wrapper {
    gap: 8px;

    @include respond(laptop) {
      width: 100%;
    }

    @include respond(tablet) {
      width: 100%;
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    .mobile_item {
      width: 100%;
      color: var(--primary);

      @include sElevation_1;
      border-radius: 16px;
      background-color: var(--bg_6);
      display: flex;
      flex-direction: column;
      padding: 16px;
      gap: 4px;

      span {
        &:nth-child(1) {
          text-decoration: underline;
        }
      }

      .mobile_date {
        color: var(--text_4);
      }
    }
  }

  .block_name {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--primary);

    @include respond(tablet) {
      margin: 0 0 8px;
    }
  }

  .table {
    max-width: 700px;
    width: 700px;
    height: 531px;
    background-color: var(--bg_6);
    max-height: 531px;
    border-radius: 16px;

    @include sElevation_1;
    overflow: hidden;

    @include respond(laptop) {
      max-width: 100%;
      width: 100%;
    }

    .table_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 14px 24px;
      border-bottom: 1px solid var(--border_9);

      p {
        color: rgb(102 102 102 / 100%);

        @include tCaption;
        margin: 0;
      }

      .height {
        width: 102px;
      }

      .hash {
        width: 148px;
      }

      .mined_on {
        width: 290px;
      }

      .total {
        width: 245px;
      }
    }

    .item {
      color: var(--primary);
      padding: 14px 24px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border_9);

      &:last-child {
        border-bottom: none;
      }

      .height {
        width: 102px;
        text-decoration: underline;
      }

      .hash {
        width: 148px;
      }

      .mined_on {
        width: 290px;
      }

      .total {
        width: 245px;
      }

      span {
        &:nth-child(1) {
          text-decoration: underline;
        }
      }
    }
  }
}
