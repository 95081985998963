@use "src/_app/styles/mixins.scss" as *;
.top {
  width: 100%;
  background-color: var(--black);
  background-image: url('/img/scan/scan-top.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 139px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white);
  border-bottom: 1px solid #242424;

  @include respond(laptop) {
    background-image: url('/img/scan/scan-top-tablet.png');
    padding: 124px 32px;
  }

  @include respond(tablet) {
    background-image: none;
    padding: 40px 16px;
  }

  &__content {
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    .top__titles {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      @include respond(tablet) {
        row-gap: 16px;
      }

      .top__title {
        margin: 0;
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
        text-align: center;
        display: inline;

        b {
          display: block;
          font-weight: 700;
          font-size: 60px;
          line-height: 72px;
        }

        @include respond(tablet) {
          font-size: 32px;
          line-height: 42px;
          text-align: start;

          b {
            font-size: 32px;
            line-height: 42px;
          }
        }
      }

      .top__subtitle {
        margin: 0;

        @include tTitle_2_semibold;
        text-align: center;

        @include respond(tablet) {
          @include tTitle_3-semibold;
          text-align: start;
        }
      }
    }
  }
}
