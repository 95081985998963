@use "src/_app/styles/mixins.scss" as *;
.search__matches {
  position: absolute;
  top: 44px;
  width: 400px;
  max-height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: var(--secondary);
  border-radius: 12px;
  overflow-y: auto;
  list-style: none;
  box-sizing: border-box;

  @include sElevation_3;
  z-index: 12;

  @include respond(tablet) {
    left: 0;
    right: 0;
    width: 100%;
  }

  .matches__item {
    padding: 12px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    background: transparent;

    @include transition;

    &:hover {
      background: var(--bg_2);
    }

    .item__title {
      @include tSubtitle_Semibold;
      color: var(--primary);
    }
  }

  .nothing_found {
    @include tSubtitle_Semibold;
    color: var(--primary);
  }
}
