@use "src/_app/styles/mixins.scss" as *;
.post__article {
  .article__img {
    max-width: 1440px;
    display: flex;
    justify-content: center;
    padding-top: 56px;

    @include respond(tablet) {
      padding-top: 24px;
    }

    .article__img_wrapper {
      position: relative;
      overflow: hidden;

      img {
        object-fit: cover;
      }

      @include respond(mobileL) {
        height: 222px;
      }

      width: 1280px;
      height: 585px;
      border-radius: 16px;

      @include respond(laptop) {
        width: 100%;
        height: 439px;
      }

      @include respond(mobileL) {
        width: 397px;
        height: 198px;
      }
    }
  }

  .content_container {
    max-width: calc(1280px + 28px);
    margin: 0 auto;
    padding: 0 14px;
  }

  .main_container {
    display: flex;
    flex-flow: row nowrap;
    row-gap: 32px;
    justify-content: space-between;
    width: 100%;
  }

  .content {
    margin-top: 56px;
    position: relative;
    max-width: calc(100% - 378px);
    flex-shrink: 0;

    @include respond(laptop) {
      max-width: calc(100% - 328px);
    }

    @include respond(tablet) {
      max-width: 100%;
      grid-template-columns: 1fr;
      margin-top: 0;
    }

    a {
      display: contents;
    }

    .content_wrapper {
      padding-bottom: 40px;

      @include respond(tablet) {
        padding: 32px 0 40px;
      }

      .content__info {
        margin-bottom: 40px;
      }

      .title {
        margin-top: 16px;
        margin-bottom: 20px;

        @include tTitle_1_demibold;
      }

      .content__inner {
        h1 {
          font-family: Golos, sans-serif;
          font-size: 48px;
          margin-bottom: 40px;

          @include respond(tablet) {
            font-size: 44px;
          }

          @include respond(mobileL) {
            font-size: 34px;
            margin-bottom: 24px;
          }
        }

        h2 {
          font-family: Golos, sans-serif;
          font-size: 26px;
          margin-bottom: 24px;
          word-wrap: break-word;

          @include respond(tablet) {
            font-size: 24px;
          }

          @include respond(mobileL) {
            font-size: 20px;
          }
        }

        h3 {
          font-size: 22px;
          font-family: Golos, sans-serif;
          margin-bottom: 12px;

          @include respond(tablet) {
            font-size: 20px;
          }

          @include respond(mobileL) {
            font-size: 18px;
          }
        }

        p {
          font-family: Golos, sans-serif;

          @include tSubtitle;
          margin: 0;
        }

        p:not(:last-child) {
          margin-bottom: 24px;
        }

        a {
          cursor: pointer;
          text-decoration: underline;
        }

        img {
          border-radius: 16px;
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }

        blockquote {
          padding: 15px;
          background: var(--bg_2);
          border-radius: 16px;
          margin-bottom: 24px;

          blockquote {
            padding: 0;
            margin-bottom: 0;

            p {
              margin-bottom: 0;
            }
          }

          p {
            margin-bottom: 24px;
            font-style: italic;
          }
        }

        ul {
          list-style-type: disc;
          list-style-position: inside;
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          li {
            display: block;
            position: relative;
            padding-left: 10px;

            &::before {
              content: '';
              position: absolute;
              display: list-item;
              left: -10px;
            }
          }
        }

        ol {
          list-style: decimal inside;
          margin-bottom: 24px;

          li {
            line-height: 24px;

            p {
              display: inline;
            }
          }
        }
      }

      .video__wrapper {
        &_top {
          margin: 40px 0;
        }

        &_bottom {
          margin-top: 40px;
        }
      }
    }

    .content__social {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
}

.linked_posts {
  .linked_posts_wrapper {
    padding: 40px 0;
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(100px, 385px) auto minmax(100px, 385px);
    column-gap: 2px;

    &.stretch {
      grid-template-columns: minmax(200px, 774px);
    }

    .divider {
      border-right: 1px solid var(--border_1);
      margin: 16px 0;

      @include respond(mobileL) {
        margin: 0;
      }
    }

    .navigation__btn {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .btn__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn__icon_wrapper {
          border: 1px solid var(--primary);
          border-radius: 50%;
          width: 32px;
          height: 32px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .btn__icon_previous,
        .btn__icon_next {
          @include rtl {
            transform: rotate(180deg);
          }
        }
      }

      .content_wrapper {
        display: flex;
        flex-direction: column;
        padding: 26px 33px;
        width: 100%;

        @include respond(mobileL) {
          padding: 4px 16px;
          align-items: center;
          justify-content: center;
        }

        .btn__name {
          font-family: Golos, sans-serif;

          @include tCaption;
          text-align: center;
          margin-bottom: 8px;
          color: var(--text_1);

          @include respond(mobileL) {
            @include tSubtitle;
            color: var(--primary);
            margin-bottom: 0;
          }
        }

        .post__title {
          font-family: Golos, sans-serif;

          text-align: center;
          color: var(--primary);

          @include tTitle_3-demibold;

          @include respond(tabletL) {
            display: none;
          }
        }
      }
    }
  }
}

.forms_wrapper {
  .get_started_wrapper {
    padding: 24px 0 56px;

    @include respond(tablet) {
      padding: 24px 0 32px;
    }
  }
}

.additional_block {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 32px;
  align-items: flex-end;
  padding: 56px 0;
  width: fit-content;

  @include respond(tablet) {
    width: 100%;
    overflow: hidden;
    align-items: center;
    padding: 32px 0 40px;
    row-gap: 24px;
  }

  &.desktop {
    display: flex;

    @include respond(tablet) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @include respond(tablet) {
      display: flex;
    }
  }
}

.content_menu {
  position: sticky;
  top: 24px;
  inset-inline-start: 100%;
  width: fit-content;
}
