@use "src/_app/styles/mixins.scss" as *;
.container {
  position: relative;

  .dropdown {
    position: absolute;
    height: 20px;
    width: 100%;
    z-index: 100;

    .dropdown__menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 16px;
      top: 10px;

      padding: 14px 16px;
      border-radius: 16px;

      background-color: var(--bg_10);
      box-shadow:
        0 16px 32px rgb(37 33 41 / 8%),
        0 0 12px rgb(37 33 41 / 4%);

      animation: dropdownFadeIn 0.2s ease-in-out forwards;
      opacity: 0;

      &_right {
        right: 0;
      }
    }

    &.top {
      top: -20px;

      .dropdown__menu {
        top: unset;
        bottom: 10px;
      }
    }
  }

  @keyframes dropdownFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
