@use "src/_app/styles/mixins.scss" as *;
.input__wrapper {
  display: flex;
  height: 52px;
  gap: 8px;
  padding: 12px;
  background-color: var(--bg_2);

  width: 100%;
  border: 1px solid var(--mainBackgroundSecondary);
  border-radius: 12px;

  @include transition;
  @include tSubtitle;

  cursor: text;
  position: relative;

  @include respond(tablet) {
    min-width: 0;
  }

  &:hover {
    background-color: var(--bg_6);
  }

  &:focus-within {
    background-color: var(--secondary);

    border: 1px solid var(--mainBackgroundSecondary);
  }

  .children {
    display: flex;
    width: auto;
  }

  .input {
    background: transparent;
    border: none;
    color: var(--primary);
    width: 100%;

    &:focus-visible {
      border: none;
      outline: none;
    }
  }

  .icon_search {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c4c4c4;
  }

  .icon_clear_wrapper {
    overflow: hidden;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon_clear {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--tertiary);

    @include transition;

    &:hover {
      color: var(--secondary);
    }

    &:active {
      color: var(--primary);
    }
  }
}
