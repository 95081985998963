@use "src/_app/styles/mixins.scss" as *;
.search__container {
  max-width: 800px;
  width: 100%;
  background: #242424;
  border-radius: 12px;
  margin: 0 auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 16px 18px 0;

  @include rtl {
    padding-right: 0;
    padding-left: 16px;
  }

  &.full_width {
    max-width: 100%;
  }

  .button {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 8px 16px;
    border: none;

    &__icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg path {
      transition: fill 0.3s ease-in-out;
      fill: var(--secondary);
    }

    button {
      @include tButton_2;

      background: transparent;
    }
  }

  .search__input {
    border: none;
    box-shadow: none;
    width: calc(100% - 20px);
    background: transparent;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    &:focus-within {
      background-color: transparent;

      border: transparent;
    }

    input {
      border: none;
      color: var(--white);
      width: 100%;

      &:focus {
        background-color: transparent;
      }
    }

    &.desktop {
      display: flex;
      align-items: center;
    }

    &.mobile {
      display: none;

      @include respond(tablet) {
        display: flex;
      }
    }
  }

  .isTransactionsPage {
    input {
      color: var(--primary);
    }
  }

  &:not(.isTransactionsPage) {
    .button {
      background: rgb(255 255 255 / 18%);

      svg {
        path {
          fill: var(--white);
        }
      }

      &:hover {
        background: #fff;

        svg path {
          fill: var(--black);
        }
      }

      &:active {
        background: rgb(255 255 255 / 70%);

        svg path {
          fill: var(--black);
        }
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.7;
      }

      &.noCoin {
        pointer-events: none;

        svg path {
          fill: #666;
        }
      }
    }
  }
}

.notFoundContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 802px;
  word-break: break-word;
}

.isTransactionsPage {
  background-color: var(--bg_2);

  .button {
    padding: 4px 10px;
  }
}

.divider {
  height: 100%;
  width: 1px;
  background: #444;
  margin: 0 16px;

  &.transaction {
    background: var(--border_1);
  }

  @include respond(tablet) {
    margin: 0 12px;
  }
}
