@use "src/_app/styles/mixins.scss" as *;
.post_item_container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include respond(mobileL) {
    gap: 16px;
  }

  .image_block {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    height: 250px;
    border: 1px solid var(--border_1);

    img {
      object-fit: cover;
    }

    @include respond(laptop) {
      height: 200px;
    }
  }

  .post_info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include respond(mobileL) {
      gap: 8px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
      gap: 8px;
    }

    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.44px;

      @include respond(mobileL) {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.24px;
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.12px;
    }
  }
}

.dropdown_tag {
  margin-bottom: 0 !important;
}
