@use "src/_app/styles/mixins.scss" as *;
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 56px 80px;

  @include respond(tablet) {
    padding: 56px 32px;
  }

  @include respond(mobileL) {
    padding: 32px 16px;
  }
}

.page__post {
  background: var(--secondary);
  color: var(--primary);
  height: 100%;
}

.related__posts {
  background-color: var(--bg_7);
  color: var(--primary);
}

.skeleton {
  padding: 16px;
  display: grid;
  gap: 16px;
}
