@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  display: flex;
  width: fit-content;
  align-items: center;
  color: var(--primary);
  margin-bottom: 16px;
  margin-right: 8px;

  cursor: pointer;

  padding: 6px 14px;
  border-radius: 69px;
  border: 1px solid var(--primary);
  background-color: var(--bg_10);

  @include transition;

  &.active {
    color: var(--secondary);
    background-color: var(--primary);
  }

  .title {
    margin-right: 10px;
  }
}
