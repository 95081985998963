@use "src/_app/styles/mixins.scss" as *;
.related__posts_wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(200px, 774px);
  padding: 56px 0;

  @include respond(mobileL) {
    padding: 32px 0;
  }

  .related__title {
    margin: 0;

    @include tTitle_2_demibold;
  }
}
