@use "src/_app/styles/mixins.scss" as *;
.related__item_wrapper {
  cursor: pointer;
  padding-top: 32px;

  @include respond(mobileL) {
    padding: 24px 0;
  }

  .item {
    display: grid;
    grid-template-columns: minmax(100px, 222px) 1fr;
    column-gap: 24px;

    @include respond(tabletS) {
      grid-template-columns: minmax(100px, 164px) 1fr;
      column-gap: 16px;
    }

    @include respond(mobileL) {
      grid-template-columns: minmax(100px, 106px) 1fr;
      column-gap: 16px;
    }

    .post__image {
      .image {
        overflow: hidden;
        border-radius: 16px;
        width: 222px;
        height: 164px;
        position: relative;

        img {
          object-fit: cover;
        }

        @include respond(tabletS) {
          border-radius: 8px;
          width: 164px;
          height: 106px;
        }

        @include respond(mobileL) {
          border-radius: 8px;
          width: 106px;
          height: 76px;
        }
      }
    }

    .post__content_wrapper {
      padding: 14px 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(tabletS) {
        padding: 0;
      }

      @include respond(mobileL) {
        padding: 0;
      }

      .post__title {
        margin-bottom: 10px;

        @include tTitle_3-demibold;
      }

      .post__description {
        p {
          @include tSubtitle;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

.related__item_wrapper:not(:last-child) {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding-bottom: 32px;
}
